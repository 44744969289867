<template>
<div style="height: 100%;width: 100%;"   
    v-loading="loading"
    element-loading-text="拼命加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(252, 249, 242, 0.5)">
  <el-amap
            vid="amap"
            :amap-manager="amapManager"
            :zoom="zoom"
            :center="center"
             :events="events"
            v-if="isGetOver"
          >
            <el-amap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :events="marker.events"
              :plugin="plugin"
            >
              <template>
                <img
                  class="iot-marker-img"
                  src="https://lz-filedb.oss-cn-shanghai.aliyuncs.com/iot-system/sys-program/posRed.png"
                />
              </template>
            </el-amap-marker>
          </el-amap>
</div>
</template>

<script>
let that;
import {AMapManager,lazyAMapApiLoaderInstance}  from 'vue-amap'
let amapManager = new AMapManager();
export default {
  props: {
    mapStyle: {
      type: String,
      default:'normal'
    }
  },
  watch: {
    mapStyle(newVal, oldVal) {
      this.getStyle()
    }
  },
data() {
    return {
      loading:false,
      isGetOver:false,
  zoom: 5,
      center: [116.397972, 39.906901],
      amapManager,
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              o.getCityInfo((status, result) => {
                status;
                if (result) {
                  that.center = result.center;
                  that.$nextTick();
                }
              });
            },
          },
        },
      ],
      events: {
        init(o) {
          that.getStyle()
          setTimeout(() => {
            let cluster = new AMap.MarkerClusterer(o, that.markerRefs, {
              gridSize: 80,
              renderCluserMarker: that._renderCluserMarker,
            });
            cluster;
          }, 1000);
        },
        complete: () => {
          //地图首次加载完成 并完成覆盖物加载 没有参数 只有map有这个事件
        },
        click: (event) => {
          event;
          //事件 event.target是组件实例 可以使用api方法
        },
      },
      markers: [],
}
},
  methods: {
    getPoint()  {
      this.loading=true
      that.$api.home.getPoint().then((response) => {
        if (response.code == 200) {
          // that.projectGroupArr = response.data;
          // let curEditArr = []
          //   that.projectGroupArr200 = response.data.slice(0, 50).concat(curEditArr); //取二十条展示       
          let markers = [];
          // 数据去重
          let obj = {};
        let newProjectIds =response.data.reduce(function (item, next) {
      obj[next.longitude] ? '' : obj[next.longitude] = true && obj[next.latitude] ? '' : obj[next.latitude] = true && item.push(next);
      return item;
        }, []);
        // 
        //   let map = new Map()
        //   for (let item of response.data) {
        //     if (!map.has(item.longitude) && !map.has(item.latitude)) {
        //     map.set(item)
        //   }
        // }
        newProjectIds.forEach((ele,index) => {
            markers.push({
              position: [ele.longitude, ele.latitude],
            })
            if (ele.longitude > 0 && ele.latitude > 0 && index==0) {
              that.center = [ele.longitude, ele.latitude];
            }
          });
          that.markers = markers;
          that.isGetOver = true;
          this.loading=false
        }
      });
    },
    getStyle() {
      lazyAMapApiLoaderInstance.load().then(() => {
        let map = amapManager.getMap()
        let value='amap://styles/'+this.mapStyle
        map.setMapStyle(value);
      })
    }
},
  beforeMount: function () {    
    that = this;
  },
mounted() {
  that.getPoint()
}
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.iot-marker-img,
.iot-sign {
  animation: opacity-change 1s ease-in-out infinite;
  -webkit-animation: opacity-change 1s ease-in-out infinite;
  -moz-animation: opacity-change 1s ease-in-out infinite;
  -o-animation: opacity-change 1s ease-in-out infinite;
}
.iot-marker-img {
  width: 35px;
  height: 35px;
}
.iot-sign {
  width: 15px;
  height: 15px;
}
@keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes opacity-change {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>